import React, { useState } from 'react';
import TopLine from '@rambler-components/topline';
import Modal from '@rambler-components/modal';
import Geo from '@rambler-components/geo';
import Menu from '../Menu';

import '@rambler-components/topline/styles.css?compiled';
import '@rambler-components/modal/styles.css?compiled';
import '@rambler-components/geo/styles.css?compiled';

import s from './style.css';
import './style.compiled.css?compiled';

export default function HeaderComponent(props) {
  const [userProfile, setUserProfile] = useState(undefined);
  const [isGeoOpened, setGeoOpened] = useState(false);

  return (
    <div className={s.header}>
        <TopLine
          projectCode="soft"
          projectName="софт"
          onUserInfo={setUserProfile}
          onOpenGeo={() => setGeoOpened(true)}
          search={{
            onSubmit: (e, query) => {
              e.preventDefault();
              window.location = `https://nova.rambler.ru/search?query=${encodeURIComponent(
                query
              )}`;
            },
          }}
          children={
            <Menu
              location={props.path}
              stat={{ appPlatformType: props.path ? props.path : 'all' }}
            />
          }
        />
        <Modal
          width={560}
          isOpen={isGeoOpened}
          onClose={() => setGeoOpened(false)}
        >
          <Geo onClose={() => setGeoOpened(false)} />
        </Modal>
    </div>
  );
}

/**
 * Рекламный блок в топлайне
 * Документация по рекламным кодам SSP https://confluence.rambler-co.ru/pages/viewpage.action?pageId=35701075
 */
function ToplineAd() {
  return (
    <div id="block_pad_id_549396255_547613113">
      <Helmet>
        <link
          rel="preload"
          href="//ssp.rambler.ru/capirs_async.js"
          as="script"
        />
        <script
          type="text/javascript"
          src="//ssp.rambler.ru/capirs_async.js"
          async
        />
      </Helmet>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(){
            window.begun_new_callbacks = window.begun_new_callbacks || [];
            window.begun_new_callbacks.push({ "lib": { "init": initializer } });
            function initializer() {
              Adf.banner.ssp('block_pad_id_549396255_547613113', {},{
                "begun-auto-pad": "547613113",
                "begun-block-id": "549396255"
              });
            }
          })()`,
        }}
      />
    </div>
  );
}

